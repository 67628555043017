import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

interface LogoProps {
  title: string
}

const HomeLink = styled(Link)`
  display: flex;
  justify-content: center;
  height: 30px;
`

export const Logo: FunctionComponent<LogoProps> = ({ title }) => (
  <HomeLink to={`/blog`}>
    <StaticImage
      src="../images/vanna.png"
      placeholder="blurred"
      layout="fixed"
      style={{ width: '30px', height: '30px' }}
      alt={title}
    />
  </HomeLink>
)
