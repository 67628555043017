import React from 'react'
import styled, { css } from 'styled-components'
import { MenuItem } from '../utils/models'
import { FooterReservation } from './FooterReservation'
import { Logo } from './Logo'
import { Separator } from './Separator'
import { Container } from './Common'

const StyledFooter = styled.footer`
  max-width: 100%;
  box-sizing: content-box;
  z-index: 700;
  position: relative;
  font-size: 0.9em;
  background-color: ${(props) => props.theme.colors.primary.black};
`

const FooterContainer = styled(Container)`
  padding: 15px;
  text-align: center;
  height: 100%;
  line-height: 1.1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const Copyright = styled.p`
  color: #fff;
  margin: 0;
`

const LinkStyle = css`
  color: #fff;
  text-decoration: none;
`

interface FooterProps {
  menu: MenuItem[]
  owner: string
}

const Title = styled.h3`
  font-size: 1rem;
  color: #fff;
`

const Subtitle = styled.h4`
  font-size: 0.85rem;
  color: #fff;
`

const Wrapper = styled.div``

export const Footer = ({ owner }: FooterProps) => (
  <StyledFooter>
    <FooterReservation />
    <FooterContainer>
      <Wrapper>
        <Logo title={'Vanna'} />
        <Separator size={5} />
        <Title>Vanna</Title>
        <Separator size={5} />
        <Subtitle>Plataforma de cobranças instantâneas</Subtitle>
      </Wrapper>
      <Separator size={20} />
      <div>
        <Copyright>
          <strong>{owner}</strong>&nbsp;&copy; {new Date().getFullYear()}
        </Copyright>
      </div>
    </FooterContainer>
  </StyledFooter>
)
