import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const Avatar = ({ alt, style }) => (
  <StaticImage
    src="../images/vanna.png"
    placeholder="blurred"
    layout="fixed"
    alt={alt}
    style={{
      width: '55px',
      height: '55px',
      borderRadius: '100%',
      ...style,
    }}
  />
)
