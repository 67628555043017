import styled from 'styled-components'

export const Button = styled.button`
  padding: 12px 10px;
  font-size: 0.85rem;
  border: none;
  background-color: ${(props) => props.theme.colors.primary.yellow};
  color: #ffffff;
  border-radius: 2px;
  transition: background-color 0.1s;

  :hover {
    background-color: ${(props) => props.theme.colors.primary.yellow100};
  }

  :active {
    background-color: ${(props) => props.theme.colors.primary.yellow150};
  }
`
