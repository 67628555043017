import React from "react"
import { ThemeProvider } from "styled-components"
import { DefaultTheme } from "./default-theme"
const theme: DefaultTheme = {
  layout: {
    backgroundColor: `#fafafa`,
    primaryColor: `#D4C742`,
    linkColor: `#D4C742`,
  },
  breakpoints: {
    xs: `425px`,
    sm: `576px`,
    md: `768px`,
    lg: `992px`,
    xl: `1300px`,
  },
  fonts: {
    base: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
      Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
  },
  colors: {
    primary: {
      background: `#20232a`,
      green50: `#e2d980`,
      green100: `#e2d980`,
      green150: `#ddd36c`,
      black: `#20232A`,
      yellow: `#D4C742`,
    },
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
