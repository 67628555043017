import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import reactStringReplace from 'react-string-replace'
import { Navigation } from './Navigation'
import { MenuItem } from '../utils/models'
import { LandingTopbar } from './LandingTopbar'
import Typed from 'react-typed'

const StyledHeader = styled.header`
  display: flex;
  background: ${props => props.theme.colors.primary.black};
  flex-direction: column;
  height: 350px;
  border-bottom: 2px #ededed solid;

  /* @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 30vh;
  } */
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  margin-top: 50px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 0.75em;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
`

const Title = styled.h1`
  display: block;
  color: #fff;
  opacity: 0.8;
  text-shadow: 0 5px 18px rgba(0, 0, 0, 0.07);
`

const Description = styled.h2`
  margin: 0;
  color: #fff;
  opacity: 0.5;
  font-weight: 400;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 18px;
  }
`

const StyledTopics = styled(Typed)`
  border-bottom: 3px #000 solid;
`

interface HeaderProps {
  title: string
  description: string
  topics: string[]
  menu: MenuItem[]
  search: boolean
}

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  description,
  menu,
  topics = [],
  search = true,
}) => {
  if (topics.length > 0) {
    description = reactStringReplace(description, '%TOPICS%', (match, i) => {
      return (
        <StyledTopics
          strings={topics}
          typeSpeed={50}
          backSpeed={60}
          shuffle={true}
          backDelay={1500}
          loop={true}
          key={match + i}
        />
      )
    }) as any
  }

  return (
    <>
      <LandingTopbar />
      <StyledHeader>
        <Navigation menu={menu} title={title} showSearch={search} />
        <TitleWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleWrapper>
      </StyledHeader>
    </>
  )
}
