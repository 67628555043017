import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Input } from './Input'
import { Button } from './Button'
import { Separator } from './Separator'
import { submitReservation } from '../utils/submitReservation'

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding: 50px;
  background-color: #303540;
  line-height: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: stretch;
  }
`

const FormContainer = styled.div`
  max-width: ${(props) => props.theme.breakpoints.lg};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: stretch;
  }
`

const TitleContainer = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
  }
`

const Title = styled.h2`
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
`

const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #fff;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    line-height: 1.3;
  }
`

const StyledInput = styled(Input)`
  border: none;
`

const StyledButton = styled(Button)`
  padding: 13px 25px;
  font-size: 0.85rem;
`

const StyledSeparator = styled(Separator)`
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    height: ${(props) => (props.horizontal ? props.size * 2 + 'px' : '0')};
  }
`

export const FooterReservation: FunctionComponent = () => {
  const [email, setEmail] = useState('')

  return (
    <Wrapper>
      <TitleContainer>
        <Title>Mais tempo, mais vendas, menos burocracia.</Title>
        <Subtitle>
          Descubra como a Vanna pode ajudar a levar seu negócio para o próximo
          nível!
        </Subtitle>
      </TitleContainer>
      <FormContainer>
        <StyledInput
          placeholder={'Email'}
          type={'email'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value)
          }}
        />
        <StyledSeparator size={4} horizontal={true} />
        <StyledButton onClick={() => submitReservation({ email })}>
          Assinar
        </StyledButton>
      </FormContainer>
    </Wrapper>
  )
}
