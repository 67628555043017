import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { MenuItem } from '../utils/models'
import { Search } from './Search'
import { Logo } from './Logo'
import { Menu } from './Menu'
import { Burger } from './Burger'
import { Button } from './Button'
import { Container } from './Common'

const NavContainer = styled.div<{ dark?: boolean }>`
  z-index: 1000;
  ${(props) =>
    props.dark &&
    `
    background-color: #20232a;
    position: sticky;
    top: 0;
    box-shadow: 0 0 3px rgba(0,0,0,.03), 0 3px 46px rgba(0,0,0,.07);
  `};
`

const Nav = styled(Container)`
  display: flex;
  align-items: center;
  position: relative;
`

const NavWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  height: 70px;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 90%;
  }
`

const NavMenu = styled.ul<{ mobile?: boolean }>`
  align-self: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${(props) =>
    `
    props.mobile &&
    @media (max-width: ${props.theme.breakpoints.sm}) {
      width: 80%;
      overflow-x: auto;
      overflow-y: hidden;
      mask-image: linear-gradient(to right, transparent, #000 25px, #000 90%, transparent);
    }
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

export const NavMenuItem = styled.li`
  cursor: pointer;
  display: inline-block;
  border: 0;
  background: transparent;
  outline: none;
  text-decoration: none;
`

const NavLink = styled(Link)`
  color: #fff;
  opacity: 0.8;
  padding: 16px;
  transition: opacity 0.5s;

  &:hover {
    opacity: 1;
  }
`

const SearchContainer = styled.div`
  align-self: center;
  position: relative;
`

export const ToggleSearchButton = styled.button`
  cursor: pointer;
  color: #fff;
  opacity: 0.8;
  background: none;
  outline: none;
  border: 0;
  transition: opacity 0.5s;

  &:hover {
    opacity: 1;
  }
`

interface NavigationProps {
  title: string
  menu: MenuItem[]
  showSearch: boolean
  dark?: boolean
}

const StyledButton = styled(Button)`
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 20px;
  font-size: 14px;
`

const StyledSearch = styled(SearchContainer)`
  justify-self: flex-end;
  margin-left: 20px;
`

const BurgerContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

const LogoContainer = styled.div`
  margin: 17px 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}) {
    display: none;
  }
`

export const Navigation: FunctionComponent<NavigationProps> = ({
  title,
  menu,
  dark = false,
  showSearch = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <NavContainer dark={dark}>
      <Nav>
        <NavWrapper>
          <LogoContainer>
            <Logo title={title} />
          </LogoContainer>
          <NavMenu mobile={true}>
            {menu.map((item, index) => (
              <NavMenuItem key={index}>
                <NavLink to={item.path} key={index}>
                  {item.name}
                </NavLink>
              </NavMenuItem>
            ))}
          </NavMenu>
        </NavWrapper>
        <OutboundLink href="https://conta.vanna.app">
          <StyledButton>Experimente</StyledButton>
        </OutboundLink>
        <StyledSearch>{showSearch && <Search />}</StyledSearch>
        <BurgerContainer>
          <Burger isOpen={isOpen} setIsOpen={setIsOpen} />
        </BurgerContainer>
      </Nav>
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} menu={menu} />
    </NavContainer>
  )
}
