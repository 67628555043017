import styled from "styled-components"

interface SeparatorProps {
  size: number
  horizontal?: boolean
  hideOnMobile?: boolean
}

export const Separator = styled.div<SeparatorProps>`
  margin-bottom: ${props => (props.horizontal ? "0px" : props.size + "px")};
  width: ${props => (props.horizontal ? props.size * 2 + "px" : "100%")};
  @media screen {
    max-width: 600px;
    display: ${props => (props.hideOnMobile ? "none" : "block")};
  }
`
