interface SubmitReservationProps {
  name: string
  email: string
}

export const submitReservation = async ({
  name,
  email,
}: SubmitReservationProps) => {
  const rawResponse = await fetch(`${process.env.URL_RESERVATION}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ name, email }),
  }).finally(() => {
    window.open(
      `${process.env.URL_REGISTER}?name=${name}&email=${email}`,
      '_blank',
    )
  })
  const content = await rawResponse.json()
  return content
}
