import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { MenuItem } from '../utils/models'
import { Bio } from './Bio'

interface MenuProps {
  isOpen: boolean
  setIsOpen: Function
  menu: MenuItem[]
}

const StyledMenu = styled.nav<MenuProps>`
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.primary.yellow};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  padding-top: 8rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(-100%)')};

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

const NavLink = styled(Link)`
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s linear;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 1.5rem;
    text-align: center;
  }

  &:hover {
    color: yellow;
  }
`

export const Menu = ({ isOpen, menu }: MenuProps) => {
  return (
    <StyledMenu isOpen={isOpen}>
      {menu.map((item, index) => (
        <NavLink to={item.path} key={index}>
          {item.name}
        </NavLink>
      ))}
      <Bio />
    </StyledMenu>
  )
}
