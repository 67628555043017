import React from 'react'
import styled from 'styled-components'

interface BurgerProps {
  isOpen: boolean
  setOpen: Function
}

const StyledBurger = styled.button<BurgerProps>`
  position: absolute;
  top: 1rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }
`

const Line = styled.span<BurgerProps>`
  width: 2rem;
  height: 0.25rem;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  :first-child {
    transform: ${props => (props.isOpen ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :nth-child(2) {
    opacity: ${props => (props.isOpen ? '0' : '1')};
    transform: ${props =>
      props.isOpen ? 'translateX(20px)' : 'translateX(0)'};
  }

  :nth-child(3) {
    transform: ${props => (props.isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`

export const Burger = ({ isOpen, setIsOpen }: BurgerProps) => (
  <StyledBurger onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
    <Line isOpen={isOpen} />
    <Line isOpen={isOpen} />
    <Line isOpen={isOpen} />
  </StyledBurger>
)
